import * as React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import formatCurrency from '../../utils/formatCurrency'
import styled from '@emotion/styled'

const StyledImg = styled(Img)``

const ImageContainer = styled.div``
const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
const StyledLi = styled.li`
  margin: 0;
  padding: 10px;
  list-style-type: none;
  width: 200px;
`

const StyledH3 = styled.h3`
  margin: 0;
  font-size: 16px;
`
const Amount = styled.span`
  white-space: nowrap;
`

interface IProductListProps {
  products: any[]
}
export default function ProductList({ products }: IProductListProps) {
  return (
    <StyledUl>
      {products.map(product => (
        <StyledLi>
          {/*
          <ImageContainer>
            {product.image && (
              <Link to={`/shop/${product.slug}`}>
                <StyledImg fixed={product.image.childImageSharp.fixed} />
              </Link>
            )}
          </ImageContainer>
          */}
          <StyledH3>
            <Link to={`/shop/${product.slug}`}>
              {product.name}{' '}
              <Amount>
                {!product.priceExact && <>ca. </>}
                {product.amount}
              </Amount>
            </Link>
          </StyledH3>
          {formatCurrency(product.price)}
        </StyledLi>
      ))}
    </StyledUl>
  )
}

export const productListFragment = graphql`
  fragment ProductList on ProductsJson {
    name
    amount
    slug
    price
    priceExact
    image {
      blocks
      childImageSharp {
        fluid(maxWidth: 100) {
          src
          aspectRatio
          srcSet
        }
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
