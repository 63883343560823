import * as React from 'react'
import { graphql, Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import AddToCart from '../components/shop/AddToCart'
import formatCurrency from '../utils/formatCurrency'
import ShopLayout from '../layouts/Shop'
import ProductList from '../components/shop/ProductList'

interface CategoryTemplateProps {
  data: {
    categoriesJson: {
      name: string
      slug: string
    }
    allProductsJson: {
      edges: Array<{
        node: {
          name: string
          slug: string
          price: number
        }
      }>
    }
  }
}
const CategoryTemplate: React.FC<CategoryTemplateProps> = ({ data }) => {
  const products = data.allProductsJson.edges.map(p => p.node)
  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>{data.categoriesJson.name}</h1>
          <ProductList products={products} />
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query CategoryTemplateQuery($slug: String!) {
    categoriesJson(slug: { eq: $slug }) {
      name
      slug
    }
    allProductsJson(filter: { category: { eq: $slug } }) {
      edges {
        node {
          ...ProductList
        }
      }
    }
  }
`
